var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.is = void 0;

function isType(type) {
  return function (o) {
    return {}.toString.call(o) === "[object " + type + "]";
  };
}

var is = {
  array: Array.isArray,
  boolean: isType("Boolean"),
  date: isType("Date"),
  defined: function defined(o) {
    return !(this.undefined(o) || this.null(o));
  },
  function: isType("Function"),
  map: isType("Map"),
  null: isType("Null"),
  number: isType("Number"),
  plainObject: isType("Object"),
  object: function object(o) {
    return typeof o === "object";
  },
  regExp: isType("RegExp"),
  string: isType("String"),
  symbol: isType("Symbol"),
  undefined: isType("Undefined"),
  weakMap: isType("WeakMap")
};
exports.is = is;
var _default = is;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;
const _is = exports.is;
export { _is as is };